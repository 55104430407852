import { useState } from "react";
import { Link } from "react-router-dom";
import PopupLightbox from "../components/PopupLightbox";

import imgCloseIcon from "../images/icon-close.png";

import victorB from "../images/bio/alumni/victor-belo.jpg";
import bryantG from "../images/bio/anonymous.png";
import robL from "../images/bio/alumni/rob-lewis.jpg";
import ericaP from "../images/bio/alumni/erica-pugh.jpg";
import fadiaA from "../images/bio/alumni/fadia-azar.jpg";
import rachelJ from "../images/bio/alumni/rachel-jepson.jpg";

const AlumniHighlights = () => {
  const [buttonPopup1, setButtonPopup1] = useState(false);
  const [buttonPopup2, setButtonPopup2] = useState(false);
  const [buttonPopup3, setButtonPopup3] = useState(false);
  const [buttonPopup4, setButtonPopup4] = useState(false);
  const [buttonPopup5, setButtonPopup5] = useState(false);
  const [buttonPopup6, setButtonPopup6] = useState(false);

  return (
    <div className="grid-container dark-bg">
      {/* <div class="grid-item grid-a">Emeriti</div>
      <div class="grid-item grid-b">b</div>
      <div class="grid-item grid-c">c</div> */}

      <div className="popup-wrapper">
        <div className="popup-col colC">
          <div className="popup-col-inner">
            <h2>Alumni Highlights</h2>
            <Link className="close-btn" to="/">
              <img src={imgCloseIcon} alt="close button" />
            </Link>

            <ul className="circle-container">
              <li>
                <button
                  className="image-btn image-link"
                  style={{ backgroundImage: `url(${victorB})` }}
                  onClick={() => setButtonPopup1(true)}
                >
                  <span>Victor Belo</span>
                </button>
              </li>
              <li>
                <button
                  className="image-btn image-link"
                  style={{ backgroundImage: `url(${bryantG})` }}
                  onClick={() => setButtonPopup2(true)}
                >
                  <span>Bryant Gunnerson</span>
                </button>
              </li>
              <li>
                <button
                  className="image-btn image-link"
                  style={{ backgroundImage: `url(${robL})` }}
                  onClick={() => setButtonPopup3(true)}
                >
                  <span>Rob Lewis</span>
                </button>
              </li>
              <li>
                <button
                  className="image-btn image-link"
                  style={{ backgroundImage: `url(${ericaP})` }}
                  onClick={() => setButtonPopup4(true)}
                >
                  <span>Erica Pugh</span>
                </button>
              </li>
              <li>
                <button
                  className="image-btn image-link"
                  style={{ backgroundImage: `url(${fadiaA})` }}
                  onClick={() => setButtonPopup5(true)}
                >
                  <span>Fadia Azar</span>
                </button>
              </li>

              <li>
                <button
                  className="image-btn image-link"
                  style={{ backgroundImage: `url(${rachelJ})` }}
                  onClick={() => setButtonPopup6(true)}
                >
                  <span>Rachel Jepson</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <PopupLightbox trigger={buttonPopup1} setTrigger={setButtonPopup1} columnPosition="colB">
        <h3>Victor Belo</h3>
        <p>
          Victor Belo (BS 2020) experienced firsthand the impact of COVID-19 on education; however, graduating during
          the pandemic did not prevent him from pursuing his ideal career. Still early in his professional journey, Belo
          is a senior financial reporting analyst at AdventHealth in Florida. Belo believes that even though he was born
          in Provo, Utah, his growing up in São Paulo has led him to become more empathic to those who feel left out,
          since each culture is extremely different. When he isn't in the office, Belo enjoys jamming with his guitar
          and spending time with his family. He and his wife recently welcomed two beautiful twins who keep them busy.
        </p>
      </PopupLightbox>
      <PopupLightbox trigger={buttonPopup2} setTrigger={setButtonPopup2} columnPosition="colB">
        <h3>Bryant Gunnerson</h3>
        <p>
          Bryant Gunnerson (MAcc 1994) lives in Coeur d'Alene, Idaho, and is currently a special agent for the FBI. The
          core of his responsibilities revolves around investigating various violations of federal law. When he is not
          catching criminals, Gunnerson enjoys being active, especially playing sports and running. He also loves to
          grow carnivorous plants. Gunnerson and his wife have five children, four of whom have attended BYU, with one
          who has followed in his father's footsteps and graduated with his MAcc. Gunnerson's life advice is to “enjoy
          the phase of life you are currently in. Life is going to move forward, so enjoy the moment and don't worry
          [about] what will be coming down the road.”
        </p>
      </PopupLightbox>
      <PopupLightbox trigger={buttonPopup3} setTrigger={setButtonPopup3} columnPosition="colB">
        <h3>Rob Lewis</h3>
        <p>
          Robert Lewis (BS 1994) has always wanted to follow in his father's footsteps by becoming a chief financial
          officer. Now residing in Highland, Utah, Lewis is the CFO at ROI CX Solutions/Connexus Resource Group. In his
          position, Lewis not only uses his accounting skills but also works in information systems, HR, and risk
          management. Outside of his work responsibilities, Lewis spends his time playing racquetball, golf, pickleball,
          and going on date nights with his wife of 32 years. Throughout his career, Lewis has recognized that being
          fully invested and mapping out each next step will best prepare anyone for their future. He hopes that current
          students will recognize the need to have a strong plan in mind while also being open to new possibilities.
        </p>
      </PopupLightbox>
      <PopupLightbox trigger={buttonPopup4} setTrigger={setButtonPopup4} columnPosition="colB">
        <h3>Erica Pugh</h3>
        <p>
          Erica Pugh (MAcc 2005) lives in a small Washington farming town called Colfax. Currently, Pugh works as an
          independent business owner of Nielsen Insurance and is also a crop insurance specialist. Beyond work, Pugh
          loves to go fishing and to sing with the women's choral group she recently joined. Pugh also loves to
          volunteer and enjoys serving on a local hospital board as the treasurer. After living single for 20 years,
          Pugh recently remarried, and Pugh and her spouse have nine adult children between them. Pugh finds true joy in
          her life with her family, especially in being a grandmother.
        </p>
      </PopupLightbox>
      <PopupLightbox trigger={buttonPopup5} setTrigger={setButtonPopup5} columnPosition="colB">
        <h3>Fadia Azar</h3>
        <p>
          Located just outside of Portland in Beaverton, Oregon, Fadia Azar (BS 1995) thrives in city life. Whether
          trying new restaurants or attending operas and musicals, Azar loves doing anything that involves being out on
          the town. After graduation, Azar quickly found a passion for technology and has always worked in that field. A
          little over a decade ago, Azar joined Nike as a digital process and project manager. Now, still with Nike, she
          consults as a lead program manager. Outside of city excursions and office work, Azar likes taking up new
          hobbies and expanding her skillset. Recently, she has started learning how to golf and paint.
        </p>
      </PopupLightbox>
      <PopupLightbox trigger={buttonPopup6} setTrigger={setButtonPopup6} columnPosition="colB">
        <h3>Rachel Jepson</h3>
        <p>
          Ever since graduating, Rachel Jepson (MAcc 2005) has spent her professional life working in tax and public
          accounting. Jepson's public accounting journey has led her to her current position, working as a CPA in tax
          for King and McClearly, LLC in American Fork, Utah. When she is not working, Jepson loves to volunteer with
          the Utah Foundation for the Blind and Visually Impaired. With the foundation, Jepson most enjoys playing with
          and helping coach the Utah goalball teams. In her work in both public accounting and volunteering with the
          foundation, Jepson has developed a love for working with others and witnessing their personal growth. For the
          future, Jepson hopes to keep volunteering and working in tax and public accounting.
        </p>
      </PopupLightbox>
    </div>
  );
};

export default AlumniHighlights;
