import "../components/Popup.scss";
import { Link } from "react-router-dom";
import imgCloseIcon from "../images/icon-close.png";

function Popup(props) {
  // console.log(props);

  return props.trigger ? (
    <div className="popup-wrapper dark-bg">
      <div className={`popup-col ${props.columnPosition}`}>
        <div className="popup-col-inner">
          <Link to="/" className="close-btn" onClick={() => props.setTrigger(false)}>
            <img src={imgCloseIcon} alt="close button" />
          </Link>
          {props.children}
        </div>
      </div>
    </div>
  ) : (
    ""
  );
}

export default Popup;
