import { Link, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import PopupIcons from "../components/PopupIcons";
import PopupLightbox from "../components/PopupLightbox";

// import imgY from "../images/y.png";
import imgRetirement from "../images/bio/monte-swain.jpg";
import imgEmeriti from "../images/bio/don-livingstone.jpg";

//faculty & Students
import imgBook from "../images/thumbs/books.jpg";
import imgStudentDemographic from "../images/thumbs/faculty-student-demographic.jpg";
import imgCalc from "../images/thumbs/calc.jpg";
import imgGraph from "../images/thumbs/bar-graph.jpg";
import imgSpeaker from "../images/misc/icon-speaker.jpg";
import imgPeople from "../images/thumbs/professorship.jpg";

//alumni
import imgConference from "../images/thumbs/conference.jpg";
import imgMoney from "../images/thumbs/money.jpg";
import ericaP from "../images/bio/alumni/erica-pugh.jpg";
import imgReport from "../images/thumbs/magnify-report.jpg";

//lightbox img content
import imgGraphReport from "../images/graph-fundraising-report.png";
import imgMapStat from "../images/map-stat.jpg";
import imgChartUses from "../images/chart-uses.png";
import imgChartResources from "../images/chart-resources.png";

const HomePage = () => {
  const [buttonPopupA, setButtonPopupA] = useState(false);
  const [buttonPopupB, setButtonPopupB] = useState(false);
  const [buttonPopupC, setButtonPopupC] = useState(false);

  const [buttonPopupB1, setButtonPopupB1] = useState(false);
  const [buttonPopupB2, setButtonPopupB2] = useState(false);
  const [buttonPopupB3, setButtonPopupB3] = useState(false);
  const [buttonPopupB4, setButtonPopupB4] = useState(false);
  const [buttonPopupB5, setButtonPopupB5] = useState(false);
  const [buttonPopupB6, setButtonPopupB6] = useState(false);

  const [buttonPopupC1, setButtonPopupC1] = useState(false);
  const [buttonPopupC2, setButtonPopupC2] = useState(false);
  const [buttonPopupC4, setButtonPopupC4] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (buttonPopupB) {
      navigate("/faculty-and-students/");
    } else {
      navigate("/");
    }
  }, [buttonPopupB, navigate]);

  useEffect(() => {
    if (location.pathname === "/faculty-and-students" || location.pathname === "/faculty-and-students/") {
      setButtonPopupB(true);
    }
  }, [location.pathname]);

  return (
    <>
      <div className="grid-container">
        <div className="grid-item grid-a">
          <button className="primary-buttons button-a" onClick={() => setButtonPopupA(true)}></button>
        </div>
        <div className="grid-item grid-b">
          <button className="primary-buttons button-b" onClick={() => setButtonPopupB(true)}></button>
        </div>
        <div className="grid-item grid-c">
          <button className="primary-buttons button-c" onClick={() => setButtonPopupC(true)}></button>
        </div>
        <PopupIcons trigger={buttonPopupA} setTrigger={setButtonPopupA} columnPosition="colA">
          <ul className="circle-container">
            <li>
              <Link
                to="/retirements/"
                className="image-btn image-link"
                style={{ backgroundImage: `url(${imgRetirement})` }}
              >
                <span>Retirement</span>
              </Link>
            </li>
            <li>
              <Link to="/emeriti/" className="image-btn image-link" style={{ backgroundImage: `url(${imgEmeriti})` }}>
                <span>Emeriti</span>
              </Link>
            </li>
          </ul>
        </PopupIcons>
        <PopupIcons trigger={buttonPopupB} setTrigger={setButtonPopupB} columnPosition="colB">
          <ul className="circle-container">
            <li>
              <Link
                className="image-btn image-link"
                style={{ backgroundImage: `url(${imgBook})` }}
                onClick={() => setButtonPopupB1(true)}
              >
                <span>2023-2024 Published Faculty Textbooks</span>
              </Link>
            </li>
            <li>
              <Link
                className="image-btn image-link"
                style={{ backgroundImage: `url(${imgStudentDemographic})` }}
                onClick={() => setButtonPopupB2(true)}
              >
                <span className="small">Student Demographics</span>
              </Link>
            </li>
            <li>
              <Link
                className="image-btn image-link"
                style={{ backgroundImage: `url(${imgCalc})` }}
                onClick={() => setButtonPopupB3(true)}
              >
                <span>Student Financial Aids</span>
              </Link>
            </li>
            <li>
              <Link
                className="image-btn image-link"
                style={{ backgroundImage: `url(${imgGraph})` }}
                onClick={() => setButtonPopupB4(true)}
              >
                <span>Student Recruiting Stats</span>
              </Link>
            </li>
            <li>
              <Link
                className="image-btn image-link"
                style={{ backgroundImage: `url(${imgSpeaker})` }}
                onClick={() => setButtonPopupB5(true)}
              >
                <span>Faculty Awards</span>
              </Link>
            </li>
            <li>
              <Link
                className="image-btn image-link"
                style={{ backgroundImage: `url(${imgPeople})` }}
                onClick={() => setButtonPopupB6(true)}
              >
                <span className="small">Faculty Fellowships & Professorships</span>
              </Link>
            </li>
          </ul>
        </PopupIcons>
        <PopupIcons trigger={buttonPopupC} setTrigger={setButtonPopupC} columnPosition="colC">
          <ul className="circle-container">
            <li>
              <Link
                className="image-btn image-link"
                style={{ backgroundImage: `url(${imgConference})` }}
                onClick={() => setButtonPopupC1(true)}
              >
                <span>Board of Advisors</span>
              </Link>
            </li>
            <li>
              <Link
                className="image-btn image-link"
                style={{ backgroundImage: `url(${imgMoney})` }}
                onClick={() => setButtonPopupC2(true)}
              >
                <span>Fundraising Report & Cashflow</span>
              </Link>
            </li>
            <li>
              <Link to="/alumni/" className="image-btn image-link" style={{ backgroundImage: `url(${ericaP})` }}>
                <span>Alumni Highlights</span>
              </Link>
            </li>
            <li>
              <Link
                className="image-btn image-link"
                style={{ backgroundImage: `url(${imgReport})` }}
                onClick={() => setButtonPopupC4(true)}
              >
                <span>Alumni Report #'s</span>
              </Link>
            </li>
          </ul>
        </PopupIcons>

        {/*  FACULTY POPUP ********************************************************** */}
        <PopupLightbox trigger={buttonPopupB1} setTrigger={setButtonPopupB1} columnPosition="colB" slugCategory={""}>
          <h3>2023-2024 Published Faculty Textbooks</h3>

          <p>
            <strong>Brian Spilker, John Barrick, Troy Lewis, and Ron Worsham</strong>, <br />
            McGraw Hill's Essential of Federal Taxation (2025 Edition), McGraw-Hill
          </p>
          <p>
            <strong>Brian Spilker, John Barrick, Troy Lewis, and Ron Worsham</strong>, <br />
            McGraw Hill's Taxation of Individuals and Business Entities (2025 Edition), McGraw Hill
          </p>

          <p>
            <strong>Michael Drake and Jacob Thornock</strong>, <br />
            Financial Accounting for Managers (2024 Edition), McGraw Hill
          </p>

          <p>
            <strong>Brian Spilker</strong>, <br />
            McGraw Hill's Essential of Federal Taxation (2025 Edition), McGraw-Hill
          </p>
          <p>
            <strong>Brian Spilker</strong>, <br />
            McGraw Hill's Taxation of Individuals and Business Entities (2025 Edition), McGraw Hill
          </p>
          <p>
            <strong>Scott Summers and David Wood</strong>, <br />
            Accounting Information Systems (16* Edition), Pearson
          </p>

          <p>
            <strong>Bill Tayler</strong>, <br />
            Corporate Financial Accounting (17 Edition), Cengage Learning
          </p>
        </PopupLightbox>
        <PopupLightbox trigger={buttonPopupB2} setTrigger={setButtonPopupB2} columnPosition="colB" slugCategory={""}>
          <h3>Student Demographics</h3>

          <table>
            <thead>
              <tr>
                <th></th>
                <th>MACC</th>
                <th>BS Acc</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Married</td>
                <td>47%</td>
                <td>19%</td>
              </tr>
              <tr>
                <td>Returned Missionaries</td>
                <td>83%</td>
                <td>54%</td>
              </tr>
              <tr>
                <td>Bilingual</td>
                <td>82%</td>
                <td>64%</td>
              </tr>
            </tbody>
          </table>
          <small>*The demographics for male, female, and international will be in the program highlights</small>
        </PopupLightbox>
        <PopupLightbox trigger={buttonPopupB3} setTrigger={setButtonPopupB3} columnPosition="colB" slugCategory={""}>
          <h3>Financial Aid</h3>

          <table>
            <thead>
              <tr>
                <th>BYU Marriot Financial Aid</th>
                <th>MACC</th>
                <th>BS Acc</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Number of students receiving scholarships</td>
                <td>131</td>
                <td>247</td>
              </tr>
              <tr>
                <td>Percent of students receiving scholarships</td>
                <td>32%</td>
                <td>67%</td>
              </tr>
              <tr>
                <td>Amount Awarded</td>
                <td>$236,411</td>
                <td>$701,504</td>
              </tr>
            </tbody>
          </table>
        </PopupLightbox>
        <PopupLightbox trigger={buttonPopupB4} setTrigger={setButtonPopupB4} columnPosition="colB" slugCategory={""}>
          <h3>Student Recruiting</h3>

          <table>
            <thead>
              <tr>
                <td>Student Recruiting Unique Hiring Firms</td>
                <td>44</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Top States</td>
                <td>Utah, New York, Texas, Virginia, California</td>
              </tr>
            </tbody>
          </table>

          <table>
            <thead>
              <tr>
                <th colSpan="2">
                  <h4>Public Accounting</h4>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>BS Acc</td>
                <td>48%</td>
              </tr>
              <tr>
                <td>MAcc</td>
                <td>84%</td>
              </tr>
            </tbody>
          </table>

          <table>
            <thead>
              <tr>
                <th colSpan="2">
                  <h4>Industry (Accounting, Finance, Sales, Operations)</h4>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>BS Acc</td>
                <td>30%</td>
              </tr>
              <tr>
                <td>MAcc</td>
                <td>8%</td>
              </tr>
            </tbody>
          </table>

          <table>
            <thead>
              <tr>
                <th colSpan="2">
                  <h4>Financial Services</h4>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>BS Acc</td>
                <td>11%</td>
              </tr>
              <tr>
                <td>MAcc</td>
                <td>2%</td>
              </tr>
            </tbody>
          </table>

          <table>
            <thead>
              <tr>
                <th colSpan="2">
                  <h4>Management Consulting</h4>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>BS Acc</td>
                <td>11%</td>
              </tr>
              <tr>
                <td>MAcc</td>
                <td>6%</td>
              </tr>
            </tbody>
          </table>
        </PopupLightbox>
        <PopupLightbox trigger={buttonPopupB5} setTrigger={setButtonPopupB5} columnPosition="colB" slugCategory={""}>
          <h3>Faculty Awards</h3>

          <table>
            <thead>
              <tr>
                <th>Recipient</th>
                <th>Awards</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>David Wood</td>
                <td>
                  100 most influential accountants (Accounting Today) [Note: 100+ publications, 50+ presentations, 70+
                  cases, 6 books/white papers, and more.
                </td>
              </tr>
              <tr>
                <td>N/A</td>
                <td>
                  RAST Conference Best Paper Award EYARC Catherine Banks Disruptor Award (Leadership Section of the AAA)
                </td>
              </tr>
              <tr>
                <td>Ryan Sommerfeldt</td>
                <td>MAS Section Midyear Meeting Outstanding Paper Award</td>
              </tr>
              <tr>
                <td>Doug Prawitt</td>
                <td>Joseph A. Silvoso Faculty Award (Leadership section of the AAA)</td>
              </tr>
              <tr>
                <td>Tim Seidel</td>
                <td>
                  KPMG Outstanding Published Manuscript Award (Gender Issues and Worklife Balance Section of the
                  American Accounting Association) Brant Christensen{" "}
                </td>
              </tr>
              <tr>
                <td>Monte Swain</td>
                <td>Outstanding Service Award (American Accounting Association)</td>
              </tr>
              <tr>
                <td>Cassy Budd</td>
                <td>
                  Teaching, Learning, and Curriculum Section of the American Accounting Association’s Hall of Honor
                </td>
              </tr>
              <tr>
                <td>
                  Abigail Allen, Brant Christensen, Bill Heninger, Jon Kerr, Melissa Larson, Jonathan Liljegren, Scott
                  Summers, David Wood and five students
                </td>
                <td>Notable Contribution to the Accounting Literature from the AIS section of the AAA</td>
              </tr>
            </tbody>
          </table>
        </PopupLightbox>
        <PopupLightbox trigger={buttonPopupB6} setTrigger={setButtonPopupB6} columnPosition="colB" slugCategory={""}>
          <h3>Faculty Fellowships and Professorships</h3>

          <table>
            <thead>
              <tr>
                <th>Professorship Title</th>
                <th>2023-24 Recipient</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>W. Steve Albrecht</td>
                <td>Melissa F. Lewis-Western</td>
              </tr>
              <tr>
                <td>Andersen Foundation</td>
                <td>Scott L. Summers</td>
              </tr>
              <tr>
                <td>Glenn D. Ardis</td>
                <td>David A. Wood</td>
              </tr>
              <tr>
                <td>Robert Call/Deloitte</td>
                <td>Brian C. Spilker</td>
              </tr>
              <tr>
                <td>Deloitte</td>
                <td>Monte R. Swain</td>
              </tr>
              <tr>
                <td>EY</td>
                <td>T. Jeffrey Wilks</td>
              </tr>
              <tr>
                <td>EY</td>
                <td>Troy K. Lewis</td>
              </tr>
              <tr>
                <td>John W. and Nancy S. Hardy</td>
                <td>Jacob R. Thornock</td>
              </tr>
              <tr>
                <td>E. Dee & Patricia Hubbard</td>
                <td>Melissa P. Larson</td>
              </tr>
              <tr>
                <td>KPMG</td>
                <td>F. Greg Burton</td>
              </tr>
              <tr>
                <td>LeRay McAllister/Deloitte Foundation</td>
                <td>Douglas F. Prawitt</td>
              </tr>
              <tr>
                <td>Mary & Ellis</td>
                <td>Mark F. Zimbelman</td>
              </tr>
              <tr>
                <td>Norm & Cindy Nemrow Excellence in Teaching</td>
                <td>Cassy J. Budd</td>
              </tr>
              <tr>
                <td>K. Fred Skousen</td>
                <td>Michael S. Drake</td>
              </tr>
              <tr>
                <td>Robert J. Smith</td>
                <td>William B. Tayler</td>
              </tr>
            </tbody>
          </table>
        </PopupLightbox>

        <PopupLightbox
          trigger={buttonPopupC1}
          setTrigger={setButtonPopupC1}
          columnPosition="lightbox"
          slugCategory={""}
        >
          <h3>Board of Advisors</h3>

          <table>
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Job Title</th>
                <th>Company</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Board Chair</td>
                <td>Sue Robison</td>
                <td>Tax Partner</td>
                <td>RSM US LLP</td>
              </tr>

              <tr>
                <td>Board Vice Chair</td>
                <td>Jeff Bickel</td>
                <td>Managing Tax Partner</td>
                <td>Tanner LLC</td>
              </tr>

              <tr>
                <td colSpan="4">Student Placement Professional Development Committee</td>
              </tr>

              <tr>
                <td></td>
                <td>Joni Edwards</td>
                <td>Tax Partner</td>
                <td>Tanner LLC</td>
              </tr>
              <tr>
                <td></td>
                <td>Joe Guariglia</td>
                <td>Partner, Founder</td>
                <td>Prism Partners International</td>
              </tr>
              <tr>
                <td></td>
                <td>Ed Lamb</td>
                <td>Director/Audit Committee Chair</td>
                <td>Real Industry, Inc.</td>
              </tr>
              <tr>
                <td></td>
                <td>Molly Nelson</td>
                <td>Operational Controller</td>
                <td>SeekWellD8</td>
              </tr>
              <tr>
                <td></td>
                <td>Rhonda Powell</td>
                <td>Firm Director of Tax (Retired)</td>
                <td>Moss Adams LLP</td>
              </tr>
              <tr>
                <td></td>
                <td>Joel Van Cott</td>
                <td>Office Managing Audit partner</td>
                <td>Deloitte</td>
              </tr>
              <tr>
                <td></td>
                <td>Spencer Wirthlin</td>
                <td>Senior Director</td>
                <td>Walmart</td>
              </tr>

              <tr>
                <td colSpan="4">Alumni Conference Committee</td>
              </tr>

              <tr>
                <td></td>
                <td>Jared Briggs</td>
                <td>Chief Financial Officer</td>
                <td>Strider Technologies</td>
              </tr>
              <tr>
                <td></td>
                <td>Jay Meldrum</td>
                <td>Deals Partner</td>
                <td>PwC</td>
              </tr>
              <tr>
                <td></td>
                <td>Joseph Apke</td>
                <td>Audit Partner</td>
                <td>Deloitte</td>
              </tr>

              <tr>
                <td></td>
                <td>Jonyce Bullock</td>
                <td>Chief Executive Officer</td>
                <td>Squire and Company PC</td>
              </tr>
              <tr>
                <td></td>
                <td>Shawn Goff</td>
                <td>Office Managing Audit Partner</td>
                <td>EY</td>
              </tr>
              <tr>
                <td></td>
                <td>David Haskett</td>
                <td>Senior Controller</td>
                <td>The Walt Disney Company</td>
              </tr>
              <tr>
                <td></td>
                <td>MK Mortensen</td>
                <td>Tax Partner</td>
                <td>Grant Thorton</td>
              </tr>
              <tr>
                <td></td>
                <td>Christian Peo</td>
                <td>National Managing Audit Partner</td>
                <td>KPMG</td>
              </tr>
              <tr>
                <td colSpan="4">Development/Fundraising Committee</td>
              </tr>
              <tr>
                <td></td>
                <td>Lori Nichols</td>
                <td>Sabbatical Tax Professional </td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td>Brock Griffiths</td>
                <td>Tax Partner</td>
                <td>EY</td>
              </tr>
              <tr>
                <td></td>
                <td>Bob Jensen</td>
                <td>Tax Partner</td>
                <td>KKAJ LLP</td>
              </tr>
              <tr>
                <td></td>
                <td>Jennifer Jensen</td>
                <td>Tax Partner</td>
                <td>PwC</td>
              </tr>
              <tr>
                <td colSpan="4">Curriculum/Innovations Committee</td>
              </tr>
              <tr>
                <td></td>
                <td>Travis Nielsen</td>
                <td>Audit Partner</td>
                <td>Moss Adams LLP</td>
              </tr>
              <tr>
                <td></td>
                <td>Ellen Glazerman</td>
                <td>Executive Director</td>
                <td>EY</td>
              </tr>
              <tr>
                <td></td>
                <td>Mike Baird</td>
                <td>Chief Executive Officer</td>
                <td>Accelerate Dental</td>
              </tr>
              <tr>
                <td></td>
                <td>Eric Denning</td>
                <td>Audit Partner</td>
                <td>KPMGD30</td>
              </tr>
              <tr>
                <td></td>
                <td>Christine Del Rosario</td>
                <td>Assurance Partner</td>
                <td>PwC</td>
              </tr>
              <tr>
                <td></td>
                <td>Tiffany Young</td>
                <td>Tax Partner</td>
                <td>DeloitteD32</td>
              </tr>
            </tbody>
          </table>
        </PopupLightbox>

        <PopupLightbox
          trigger={buttonPopupC2}
          setTrigger={setButtonPopupC2}
          columnPosition="lightbox"
          slugCategory={""}
        >
          <h3>Fundraising Report & Cashflow </h3>
          <img src={imgGraphReport} alt="graph report" className="img-shrink" />

          <table className="shrink">
            <thead>
              <tr>
                <th></th>
                <th>
                  <span className="legend-color green"></span>Donations to Endowments
                </th>
                <th>
                  <span className="legend-color orange"></span>Total spendable donations
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>2016</td>
                <td>$290,260.68</td>
                <td>$715,649.10</td>
              </tr>
              <tr>
                <td>2017</td>
                <td>$193,528.04</td>
                <td>$753,187.59</td>
              </tr>
              <tr>
                <td>2018</td>
                <td>$333,469.67</td>
                <td>$717,176.25</td>
              </tr>
              <tr>
                <td>2019</td>
                <td>$368,172.17</td>
                <td>$586,211.88</td>
              </tr>
              <tr>
                <td>2020</td>
                <td>$362,005.00</td>
                <td>$605,228.22</td>
              </tr>
              <tr>
                <td>2021</td>
                <td>$591,698.45</td>
                <td>$700,081.89</td>
              </tr>
              <tr>
                <td>2022</td>
                <td>$1,202,618.48</td>
                <td>$633,517.13</td>
              </tr>
              <tr>
                <td>2023</td>
                <td>$918,157.06</td>
                <td>$1,202,980.28</td>
              </tr>
            </tbody>
          </table>

          <h3 className="spacerTop">Uses</h3>
          <img src={imgChartUses} alt="graph chart uses" className="img-shrink" />

          <table className="shrink">
            <thead></thead>
            <tbody>
              <tr>
                <td>
                  <span className="legend-color orange"></span>Faculty wages, benefits, travel, stipends
                </td>

                <td>
                  <span className="legend-color green"></span>Administrative/Supplies
                </td>

                <td>
                  <span className="legend-color purple"></span>Student Scholarship, Wages, Awards, Travel
                </td>
              </tr>
            </tbody>
          </table>

          <h3 className="spacerTop">Resources</h3>
          <img src={imgChartResources} alt="graph chart resources" className="img-shrink" />

          <table className="shrink">
            <thead></thead>
            <tbody>
              <tr>
                <td>
                  <span className="legend-color orange"></span>University Budget
                </td>

                <td>
                  <span className="legend-color green"></span>Endowment Payout, Donations
                </td>
              </tr>
            </tbody>
          </table>
        </PopupLightbox>

        <PopupLightbox
          trigger={buttonPopupC4}
          setTrigger={setButtonPopupC4}
          columnPosition="lightbox"
          slugCategory={""}
        >
          <h3>Alumni Report #'s</h3>
          <img src={imgMapStat} alt="map" />
        </PopupLightbox>
      </div>
    </>
  );
};

export default HomePage;
