import React from "react";
import "../components/Popup.scss";
import { Link } from "react-router-dom";
import imgCloseIcon from "../images/icon-close.png";
// import { useNavigate } from "react-router-dom";

function PopupBio(props) {
  // let navigate = useNavigate();

  // useEffect(() => {
  //   if (props.trigger) {
  //     navigate(`/${props.slugCategory}/${props.slug}`);
  //     // console.log("popup selected", props);
  //   }
  // }, [navigate, props]);

  const closeModal = () => {
    // navigate(`/${props.slugCategory}/`);
    props.setTrigger(false);
    // window.location.href = `/${props.slugCategory}/`;
    // console.log("popup closed", props.slugCategory);
  };

  return props.trigger ? (
    <div className="popup-wrapper dark-bg">
      <div className={`popup-col ${props.columnPosition}`}>
        <Link className="close-btn" onClick={closeModal}>
          <img src={imgCloseIcon} alt="Close button" />
        </Link>
        <div className="lightbox-popup-col-inner">{props.children}</div>
      </div>
    </div>
  ) : (
    ""
  );
}

export default PopupBio;
