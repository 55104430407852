import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Emeriti from "./pages/Emeriti";
import Retirements from "./pages/Retirements";
import AlumniHighlights from "./pages/AlumniHighlights";
// import NotFound from "./pages/404";

import logoBrand from "./images/logo/byu-school-of-accountancy-logo.png";
import logoSOA from "./images/logo/logo-soa.png";
import logoAnnualReport from "./images/logo/logo-annual-report.png";

import ReactGA from "react-ga4";

ReactGA.initialize("G-591437YLLN");
ReactGA.send({ hitType: "pageview", page: "/", title: "Home | BYU SOA" });
ReactGA.send({ hitType: "pageview", page: "/faculty-and-students", title: "Faculty and Students | BYU SOA" });
ReactGA.send({ hitType: "pageview", page: "/emeriti", title: "Emeriti | BYU SOA" });
ReactGA.send({ hitType: "pageview", page: "/retirements", title: "Retirements | BYU SOA" });
ReactGA.send({ hitType: "pageview", page: "/slumni", title: "Alumni | BYU SOA" });

function App() {
  return (
    <>
      <div className="logo-brand">
        <img src={logoBrand} alt="BYU Marriott Logo" />
      </div>
      <div className=" logo-soa">
        <img src={logoSOA} alt="BYU SOA Logo" />
      </div>
      <div className="logo-annual-report">
        <img src={logoAnnualReport} alt="BYU Annual Report Logo" />
      </div>

      <div className="main-bg">
        <Routes>
          <Route index element={<HomePage />} />
          <Route path="/faculty-and-students" element={<HomePage />} />
          <Route path="/emeriti" element={<Emeriti />} />
          <Route path="/retirements" element={<Retirements />} />
          <Route path="/alumni" element={<AlumniHighlights />} />
          <Route path="*" element={<HomePage />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
