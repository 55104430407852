import { useState } from "react";
import { Link } from "react-router-dom";
import PopupLightbox from "../components/PopupLightbox";

import imgCloseIcon from "../images/icon-close.png";

import donL from "../images/bio/don-livingstone.jpg";
import fredS from "../images/bio/fred-streuling.jpg";
import jayS from "../images/bio/jay-smith.jpg";
import robertG from "../images/bio/robert-gardner.jpg";

const Emeriti = () => {
  const [buttonPopup1, setButtonPopup1] = useState(false);
  const [buttonPopup2, setButtonPopup2] = useState(false);
  const [buttonPopup3, setButtonPopup3] = useState(false);
  const [buttonPopup4, setButtonPopup4] = useState(false);

  return (
    <div className="grid-container dark-bg">
      {/* <div class="grid-item grid-a">Emeriti</div>
      <div class="grid-item grid-b">b</div>
      <div class="grid-item grid-c">c</div> */}

      <div className="popup-wrapper">
        <div className="popup-col colA">
          <div className="popup-col-inner">
            <h2>Emeriti</h2>
            <Link className="close-btn" to="/">
              <img src={imgCloseIcon} alt="close button" />
            </Link>

            <ul className="circle-container">
              <li>
                <button
                  className="image-btn image-link"
                  style={{ backgroundImage: `url(${donL})` }}
                  onClick={() => setButtonPopup1(true)}
                >
                  <span>Don Livingstone</span>
                </button>
              </li>
              <li>
                <button
                  className="image-btn image-link"
                  style={{ backgroundImage: `url(${fredS})` }}
                  onClick={() => setButtonPopup2(true)}
                >
                  <span>Fred Streuling</span>
                </button>
              </li>
              <li>
                <button
                  className="image-btn image-link"
                  style={{ backgroundImage: `url(${jayS})` }}
                  onClick={() => setButtonPopup3(true)}
                >
                  <span>Jay Smith</span>
                </button>
              </li>
              <li>
                <button
                  className="image-btn image-link"
                  style={{ backgroundImage: `url(${robertG})` }}
                  onClick={() => setButtonPopup4(true)}
                >
                  <span>Robert Gardner</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <PopupLightbox trigger={buttonPopup1} setTrigger={setButtonPopup1} columnPosition="colB">
        <h3>Don Livingstone</h3>
        <p>
          <iframe
            src="https://www.youtube.com/embed/lpHVENTSjBc"
            title="Don Livingstone Emeriti Interview"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            className="video-player"
          ></iframe>
        </p>
      </PopupLightbox>
      <PopupLightbox trigger={buttonPopup2} setTrigger={setButtonPopup2} columnPosition="colB">
        <h3>Fred Streuling</h3>
        <p>
          <iframe
            src="https://www.youtube.com/embed/J2ABJEyX9CM"
            title="Fred Streuling Emeriti Interview"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            className="video-player"
          ></iframe>
        </p>
      </PopupLightbox>
      <PopupLightbox trigger={buttonPopup3} setTrigger={setButtonPopup3} columnPosition="colB">
        <h3>Jay Smith</h3>
        <p>
          <iframe
            src="https://www.youtube.com/embed/387Nz_k-chc"
            title="Jay Smith Emeriti Interview"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            className="video-player"
          ></iframe>
        </p>
      </PopupLightbox>
      <PopupLightbox trigger={buttonPopup4} setTrigger={setButtonPopup4} columnPosition="colB">
        <h3>Robert Gardner</h3>
        <p>
          <iframe
            src="https://www.youtube.com/embed/lLm2ZWi030k"
            title="Robert Gardner Emeriti Interview"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            className="video-player"
          ></iframe>
        </p>
      </PopupLightbox>
    </div>
  );
};

export default Emeriti;
