import { useState } from "react";
import { Link } from "react-router-dom";
import PopupLightbox from "../components/PopupLightbox";

import imgCloseIcon from "../images/icon-close.png";

import gregB from "../images/bio/greg-burton.jpg";
import monteS from "../images/bio/monte-swain.jpg";
import ronW from "../images/bio/ron-worsham.jpg";
import markZ from "../images/bio/mark-zimbelman.jpg";

const Retirements = () => {
  const [buttonPopup1, setButtonPopup1] = useState(false);
  const [buttonPopup2, setButtonPopup2] = useState(false);
  const [buttonPopup3, setButtonPopup3] = useState(false);
  const [buttonPopup4, setButtonPopup4] = useState(false);

  return (
    <div className="grid-container dark-bg">
      {/* <div class="grid-item grid-a">Emeriti</div>
      <div class="grid-item grid-b">b</div>
      <div class="grid-item grid-c">c</div> */}

      <div className="popup-wrapper">
        <div className="popup-col colA">
          <div className="popup-col-inner">
            <h2>Retirements</h2>
            <Link className="close-btn" to="/">
              <img src={imgCloseIcon} alt="close button" />
            </Link>
            {/* {props.children} */}

            <ul className="circle-container">
              <li>
                <Link
                  className="image-btn image-link"
                  style={{ backgroundImage: `url(${gregB})` }}
                  onClick={() => setButtonPopup1(true)}
                >
                  <span>Greg Burton</span>
                </Link>
              </li>
              <li>
                <Link
                  className="image-btn image-link"
                  style={{ backgroundImage: `url(${monteS})` }}
                  onClick={() => setButtonPopup2(true)}
                >
                  <span>Monte Swain</span>
                </Link>
              </li>
              <li>
                <Link
                  className="image-btn image-link"
                  style={{ backgroundImage: `url(${ronW})` }}
                  onClick={() => setButtonPopup3(true)}
                >
                  <span>Ron Worsham</span>
                </Link>
              </li>
              <li>
                <Link
                  className="image-btn image-link"
                  style={{ backgroundImage: `url(${markZ})` }}
                  onClick={() => setButtonPopup4(true)}
                >
                  <span>Mark Zimbelman </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <PopupLightbox
        trigger={buttonPopup1}
        setTrigger={setButtonPopup1}
        columnPosition="colB"
        slugCategory={"emeriti"}
        slug={"greg-burton"}
      >
        <h3>Greg Burton</h3>
        <p>
          Greg Burton joined the SOA faculty in 2001 as an instructor of auditing, accounting information systems,
          international accounting, and international business. Burton led numerous study abroad programs and coauthored
          the proposal for a $500,000 donation from EY to expand international opportunities for BYU Marriott faculty
          and students. He has presented at conferences in China, Greece, and across the United States and is fluent in
          German, Serbian, and Croatian. Burton has served on national and international accounting associations and is
          published in prominent academic journals. He has been honored with many distinguished teaching awards,
          including the BYU Marriott Excellence in Teaching Award (2016), the Ian Hague Award for Outstanding Service to
          the International Accounting Section from the American Accounting Association (2020), and the KPMG
          Professorship (2019). Burton’s special interest in international business and extensive international
          experience has helped to foster a global mindset for those in the SOA.
        </p>
      </PopupLightbox>
      <PopupLightbox
        trigger={buttonPopup2}
        setTrigger={setButtonPopup2}
        columnPosition="colB"
        slugCategory={"emeriti"}
        slug={"monte-swaim"}
      >
        <h3>Monte Swain</h3>
        {/* <p>
          Monte Swain joined the SOA faculty in 1991 to teach and research management accounting in 1991. Swain took a
          one-year academic leave in 1999 to serve as the CFO for Authorize. Following his return to BYU Marriott, he
          served as the associate director of the MBA and executive MBA programs from 2011 to 2013. He also served as
          the management accounting section president of the American Accounting Association from 2015 to 2016 and the
          director of the nonprofit Profitability Analytics Center of Excellence since 2019. Swain has received several
          notable teaching awards, including the Deloitte Professorship in 2003 and BYU Marriott MBA Core Professor of
          the Year in 2021. He has presented his research at more than 75 conferences, contributed to innumerous
          publications, and was involved as an advisor, consultant, or director on many professional committees. While
          highly accomplished, Swain is best known for his lifelong priority of lifting others.
        </p> */}
        <p>
          In 1991 Deloitte & Touche Professor of Accounting Monte Swain began teaching at the BYU Marriott School of
          Business. He was just 29 years old—the same age as half of his students. Now, after 33 years of enriching and
          inspiring the next generation of business students, Swain is teaching his final semester in the School of
          Accountancy (SOA).
        </p>
        <p>
          Swain’s love of business began when his grandfather gave him two Swedish krona. “I held those coins and was
          just transfixed by the idea that these coins have been places I've never been and were part of stories I've
          never known,” Swain remembers.
        </p>
        <p>
          As he grew, so did his coin collection—and his passion for business. After he graduated with a PhD from
          Michigan State University, he and his wife, Shannon, had no plans of coming back to Utah. “We had such a great
          experience living in Michigan, but BYU made an offer I couldn’t refuse,” Swain says. “We followed with prayer,
          and that direction brought us back here, much to our surprise and much to our blessing.”
        </p>
        <a
          href="https://marriott.byu.edu/stories/employee-spotlight/collecting-a-legacy"
          className="btn-cta"
          target="_blank"
          rel="noreferrer"
        >
          Read Monte Swain's full Employee Spotlight
        </a>
      </PopupLightbox>
      <PopupLightbox
        trigger={buttonPopup3}
        setTrigger={setButtonPopup3}
        columnPosition="colB"
        slugCategory={"emeriti"}
        slug={"ron-worsham"}
      >
        <h3>Ron Worsham</h3>
        {/* <p>
          Ron Worsham was inspired to join the SOA faculty in 1994 after earning both his bachelor’s degree in
          accounting and his MAcc from BYU Marriott, followed by a PhD in accounting from the University of Florida. As
          an associate professor, Worsham focused on tax, including tax compliance, taxes and business strategy, and
          professional tax judgment. Worsham has been a visiting professor at the University of Chicago Booth School of
          Business and The University of Texas at Austin, and he has contributed to several textbooks and numerous
          academic journal articles on taxation. He has served on more than a dozen national taxation associations,
          received several national and university fellowships, and consulted with companies such as Deloitte, EY, KPMG,
          Moss Adams, PwC, and RSM US. Worsham is recognized at BYU Marriott for his efforts to connect theory to
          real-world practice in the classroom.
        </p> */}
        <p>
          Associate professor of accounting Ron Worsham believes that persistent improvements can push the boundaries of
          what one originally thinks is possible. Worsham has seen the fruits of dedicated labor manifest over and over
          again in his 30 years at the BYU Marriott School of Business—both in himself and in his students.
        </p>

        <p>
          “You’re constantly evolving—you’re growing—and you never know how far you can go,” Worsham says. “Back when I
          was a student here, I never would have thought that I’d be able to do some of the things that I do now.”
        </p>
        <p>
          While he was a student at BYU, Worsham worked with several School of Accountancy (SOA) faculty as a research
          assistant. “Two of them mentioned I might think about getting a PhD—they thought that I might be good at it,”
          he says. “But at the time, I had my heart set on graduating with a master’s degree and going out to get some
          work experience.”
        </p>
        <a
          href="https://marriott.byu.edu/stories/employee-spotlight/growing-ever-upward"
          className="btn-cta"
          target="_blank"
          rel="noreferrer"
        >
          Read Ron Worsham's full Employee Spotlight
        </a>
      </PopupLightbox>
      <PopupLightbox
        trigger={buttonPopup4}
        setTrigger={setButtonPopup4}
        columnPosition="colB"
        slugCategory={"emeriti"}
        slug={"mark-zimbelman"}
      >
        <h3>Mark Zimbelman</h3>
        {/* <p>
          Mark Zimbelman joined the SOA faculty in 1999 to teach courses on auditing and fraud examination. Zimbelman
          graduated magna cum laude from BYU’s accounting program in 1984, after which he gained six years of experience
          working as a controller and an auditor in public accounting, followed by three years of professorship at the
          University of Oklahoma. While at BYU Marriott, he has developed a national reputation for his research on
          auditors’ detection of financial statement fraud, which he has published in prestigious accounting research
          journals and the textbook Fraud Examination. Zimbelman has also worked on multiple editorial boards for top
          accounting journals and continues to maintain a blog, FraudBytes. In 2013 he was awarded BYU Marriott’s
          Scholarly Excellence Award and received the Mary and Ellis Professorship. Zimbelman has dedicated his career
          to helping students find intellectual and spiritual truth.
        </p> */}

        <p>
          For Mary & Ellis professor Mark Zimbelman, teaching at the BYU Marriott School of Business is about helping
          students find spiritual and intellectual truth. As he retires from the School of Accountancy (SOA), he
          reflects on how his faith has helped him make a lasting impact through both his research and his interactions
          with students.
        </p>
        <p>
          Almost 40 years ago, after he graduated from BYU Marriott with an undergraduate degree in accounting,
          Zimbelman worked in public accounting and auditing—but he felt that something was missing. “I put together a
          spreadsheet with a whole bunch of different career paths and evaluated them on several different dimensions:
          family life, career satisfaction, stress, etc.” Zimbelman says. “Being a professor rose to the top.”
        </p>
        <p>
          So with encouragement from his wife, Zimbelman returned to school. After earning his PhD from the University
          of Arizona, he began working as a professor. He kept in contact with the BYU Marriott accounting program
          through his early years at other universities. “My wife and I were praying,” he says, “and I told the Lord
          that if I got an offer at BYU, I would take it because I love BYU. So I better not get an offer if He doesn't
          want me there.”
        </p>
        <a
          href="https://marriott.byu.edu/stories/employee-spotlight/teaching-truth"
          className="btn-cta"
          target="_blank"
          rel="noreferrer"
        >
          Read Mark Zimbelman's full Employee Spotlight
        </a>
      </PopupLightbox>
    </div>
  );
};

export default Retirements;
